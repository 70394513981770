<template lang="pug">
.template-chooser-upcoming-filtered(v-if="templates.length")
  .d-flex.justify-content-between.align-items-end.mb-6
    .collection-previews-heading.collection-previews-heading--title
      OmHeading.mb-6.font-size-3(h3) {{ $t('seasonal') }}
    .collection-previews-heading
      OmLink.text-nowrap(
        primary
        withIconRight
        @click="$router.push({ name: 'seasonal-collection' })"
      ) {{ $t('browseUseCases') }}
  .d-flex.row(ref="block")
    .col-12.col-lg-4.upcoming-season(
      v-for="template in templates"
      :key="`upcoming-${template.template.useCase}-${template.template._id}`"
    )
      TemplateBox(
        :title="getTitle(template.template.useCase)"
        @observable="addObservable($event.$el)"
        :key="`seasonal-${template.template.useCase}-${template.template._id}`"
        heap-event="new-selector-campaign-collection"
        :template="template.template"
        :baseThemeKitName="template.season"
      )
</template>

<script>
  import { mapActions, mapState, mapGetters } from 'vuex';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import ssrMixin from '@/mixins/ssr';
  import homepageBlockMixin from '../../mixins/homepageBlock';

  export default {
    name: 'UpcomingSeasons',
    components: {
      TemplateBox: () => import('@/components/TemplateChooser/components/TemplateBox.vue'),
    },
    mixins: [homepageBlockMixin, ssrMixin, observableCollectionMixin],
    data: () => ({ ssrBoxSelector: '.upcoming-season .template-thumbnail-box' }),
    computed: {
      ...mapState(['accountType']),
      ...mapState('templateChooser', ['loading', 'seasonalTemplates']),
      ...mapGetters('templateChooser', ['upcomingSeasons']),
      ...mapState('useCase', ['useCaseMap']),
      hasResult() {
        return Object.keys(this.seasonalTemplates)
          .flat()
          .find((season) => this.getFirstTemplateWithUseCase(season));
      },
      templates() {
        const templates = this.upcomingSeasons
          .map((season) => {
            const templates = this.seasonalTemplates[season.slug];
            return templates.slice(0, 3).map((template) => ({ template, season: season.name }));
          })
          .flat();
        return templates.length >= 6 ? templates.slice(0, 6) : templates.slice(0, 3);
      },
    },
    async created() {
      this.$bus.$on('filter-changed', this.fetch);
      this.$bus.$on('search-changed', this.fetch);
    },
    async mounted() {
      this.$refs.block.__comp__ = this;
      this.addObservable(this.$refs.block);
    },
    beforeDestroy() {
      if (this.intersectionObserver) {
        this.intersectionObserver.disconnect();
      }
      this.$bus.$off('filter-changed', this.fetch);
      this.$bus.$off('search-changed', this.fetch);
    },
    methods: {
      ...mapActions('templateChooser', ['fetchSeasonal']),
      async fetch() {
        await this.fetchSeasonal();
      },
      getTitle(useCaseId) {
        return this.useCaseMap?.[useCaseId]?.name?.[this.$i18n.locale];
      },
      getRoute(seasonSlug) {
        return {
          name: 'seasonal-templates',
          params: { slug: seasonSlug },
        };
      },
      track(event) {
        this.$emit('trackActivity', {
          ...event,
          component: this.trackComponentName,
        });
      },
      getFirstTemplateWithUseCase(season) {
        return this.seasonalTemplates?.[season].find(({ useCase }) => !!useCase) ?? false;
      },
      getPossibleTemplates() {
        return this.hasResult;
      },
    },
  };
</script>
<style lang="sass" scoped>
  .row
    &.block
      margin-bottom: unset
</style>
